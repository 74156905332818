import React from "react"
import { Link } from "gatsby"
import { ImLocation } from "react-icons/im"
import { BsEmojiSmileFill } from "react-icons/bs"
import { FaMicrophone } from "react-icons/fa"
import { FaBook } from "react-icons/fa"
import Table from "react-bootstrap/Table"
import Carousel from "react-bootstrap/Carousel"
import { AnimationOnScroll } from "react-animation-on-scroll"
import Countdown from "react-countdown"
import hMinister from "../images/Hminister.jpeg"
import g2Logo from "../images/g20-logo.png"

export default function OldHero() {
  const Completionist = () => (
    <div>
      <span>You are good to go!</span>
    </div>
  )

  const carousalData = [
    {
      text: "Inauguration & Welcome Note",
    },
    {
      text: "Launch of Indian ESG Network & Logo release",
    },
    {
      text: "ESG (Indian ancient wisdom perspective)",
    },
    {
      text: "The Power of ESG: Empowering Local Communities",
    },
    {
      text: "Advancing Learning Perspectives in the ESG Ecosystem",
    },
    {
      text: "Chief Guest Address",
    },
    {
      text: "Driving Towards Sustainability: The Automobile Industry & Large Corporate Perspective",
    },
    {
      text: "Indian ESG Awards 2022-2023",
    },
    {
      text: "ESG Conscious March for People, Planet and Profit - The Journey from India@75 to India@100",
    },
    {
      text: "ESG for Everyone - Large Corporates, MSMEs, Social Enterprises and Not For Profits",
    },
    {
      text: "Capacity Building for ESG - Primary, Secondary and Tertiary.",
    },
    {
      text: "ESG Impact Assessments - Measuring Achievements, Celebrating & Scaling",
    },
    {
      text: "Vote of Thanks and Closing Ceremony",
    },
  ]

  const date2 = new Date("03/13/2023")
  const diff = date2.getTime() - Date.now()
  let newDate = new Date().toISOString()
  const splitNewDate = newDate.split("T")
  newDate = splitNewDate[0]
  return (
    <>
      <section>
        <div className="row  mb-5 text-center justify-content-center">
          <div className="col-12 col-md-10 pl-md-5 pr-md-0 text-md-left">
            <h3
              className="font-weight-bold mt-3 mb-md-0"
              style={{ color: "#f3963c" }}
            >
              Catalysing India's ESG Conscious March from @75 years to @100
              years of Independence
            </h3>
            <div className="">
              Ideating and creating an implementation support network for
              Government, Corporates, Foundations and Social Organizations in
              order to achieve the Sustainable Development Goals (SDGs)
            </div>
          </div>
          <div className="col-12 col-md-2 pl-md-5 pr-md-0 text-md-left">
            <img alt={``} src={g2Logo} className="mt-2" width="50%" />
          </div>
        </div>

        <div className="row px-5">
          {/* <div className="col-md-1"></div> */}

          <div className="col-12 col-md-12 col-lg-5">
            <div className="mb-4">
              <a
                target="_blank"
                className="button"
                href="https://docs.google.com/forms/d/163qqaGlcR3IVjbS5ju-yHE3B4dxQ2fZp0l63wAAneQE/edit"
              >
                Join Our Network
              </a>
            </div>

            <div
              style={{
                padding: "12px",
                background: "#2769b2",
                color: "#fff",
                fontSize: "20px",
                marginBottom: "2%",
              }}
            >
              1st Edition Summit and Awards 2023
              <br />
              Completed Successfully.{" "}
              <Link to="/agenda">
                <button
                  className="btn btn-success"
                  style={{ background: "white", color: "green" }}
                >
                  Click here for details
                </button>
              </Link>
            </div>

            <div className="row align-items-center mt-5">
              <div className="col-12 col-lg-4">
                <img alt={``} src={hMinister} />
              </div>
              <div className="col-12 col-lg-8">
                Chief Guest: Shri Bhagwanth Khuba
                <br />
                Hon'ble Union Minister of State, Ministry of Chemicals &
                Fertilizers and Ministry of New & Renewable Energy, Government
                of India
                <br />
                {/* <img src={g2Logo} className="mt-2" width="28%" /> */}
              </div>
            </div>
            <h4 className="fw-bold mt-4">
              Indian ESG Conclave March 2023 – Agenda
            </h4>
            <div>
              <Carousel className="mt-4">
                {carousalData.map(data => {
                  return (
                    <Carousel.Item className="hero-carousel-item">
                      <div className="justify-content-center text-center">
                        {data.text}
                      </div>
                    </Carousel.Item>
                  )
                })}
              </Carousel>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-7 text-center">
            {/* <div className="row"> */}
            {/* <div className="col-12 col-md-2"></div> */}
            <div className="pl-md-5 pr-md-0 text-center text-md-left">
              {/* Table */}
              <h6>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp animate__slow"
                  // animateOut="animate__fadeInRight"
                >
                  <Table responsive striped bordered className="table-custom">
                    <thead>
                      <tr>
                        <th>Conclave</th>
                        <th>Month</th>
                        <th>Venue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Indian ESG Network <br />
                          Launch & National Conclave (2022-23)
                        </td>
                        <td>
                          16<sup>th</sup> March 2023
                          <br />
                          10 am-5:30 pm
                        </td>
                        <td>
                          Silver Oak Hall, India Habitat Centre, New Delhi
                          <br />
                          (Nearest Entry: Gate No.3)
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Table responsive bordered className="table-custom">
                    <thead>
                      <tr>
                        <th>Conclave</th>
                        <th>Month</th>
                        <th>City</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>South East </td>
                        <td>May 2023</td>
                        <td>Visakhapatnam</td>
                      </tr>
                      <tr>
                        <td>North</td>
                        <td>July 2023</td>
                        <td>Chandigarh</td>
                      </tr>
                      <tr>
                        <td>North East</td>
                        <td>September 2023</td>
                        <td>Guwahati</td>
                      </tr>
                      <tr>
                        <td>West</td>
                        <td>November 2023</td>
                        <td>Goa</td>
                      </tr>
                      <tr>
                        <td>South</td>
                        <td>January 2024</td>
                        <td>Auroville/ Puducherry</td>
                      </tr>
                      <tr>
                        <td>National Conclave</td>
                        <td>February 2024</td>
                        <td>New Delhi</td>
                      </tr>
                    </tbody>
                  </Table>
                </AnimationOnScroll>
              </h6>
            </div>
            {/* <div className="d-flex flex-column flex-sm-row mt-3">
              <Link
                to="/register"
                className="button mb-4"
                style={{ marginRight: "5px" }}
              >
                Register Now
              </Link>
              <Link to="/nominate" className="button mb-4">
                Nominate Now
              </Link>
            </div> */}
            {/* <div className="col-12 col-md-2"></div> */}
            {/* </div> */}
          </div>
          {/* <div className="col-md-1"></div */}
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* ===========================section====================== */}
        <div className="container-fluid count-bg mt-3">
          <div className="">
            <div className="row text-white">
              <div className="col counting-number">
                <div>
                  <ImLocation className="stats-icon" />
                  <span>7</span>
                  <p>City Editions</p>
                </div>
              </div>
              <div className="col counting-number">
                <div>
                  <BsEmojiSmileFill className="stats-icon" />
                  <span>1000</span>+<p>Delegates</p>
                </div>
              </div>
              <div className="col counting-number">
                <div>
                  <FaMicrophone className="stats-icon" />
                  <span>50</span>+<p>Speakers</p>
                </div>
              </div>
              <div className="col counting-number">
                <div>
                  <FaBook className="stats-icon" />
                  <span>400</span>+<p>Partners</p>
                </div>
              </div>
              {/* <div className="col counting-number">
                <div> */}
              {/* <div
                    className="text-end custom-span card shadow-lg"
                    style={{
                      background: "rgba(255, 255, 255, 0.5)",
                      backdropFilter: "blur(10px)",
                      // position: "fixed",
                      // bottom: "33px",
                      // right: "0px",
                      // zIndex: "1",
                    }}
                  > */}
              {/* <div className="card-body my-2">
                      <h5 className="registration">
                        Registration End Date: 12-March-2023
                      </h5>

                      <Countdown date={Date.now() + diff}>
                        <Completionist />
                      </Countdown>

                      <div
                        style={{
                          position: "absolute",
                          right: "225px",
                          top: "112px",
                          fontSize: "16px",
                        }}
                      >
                        Days
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "112px",
                          fontSize: "16px",
                          right: "157px",
                        }}
                      >
                        Hours
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          right: "94px",
                          top: "112px",
                          fontSize: "16px",
                        }}
                      >
                        Min
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          right: "27px",
                          top: "112px",
                          fontSize: "16px",
                        }}
                      >
                        Sec
                      </div>
                    </div> */}
              {/* </div>
                </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
